import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api.member/login',
    // url: '/icao/login/index',
    method: 'post',
    data
  })
}

export function signup(data) {
  return request({
    url: '/icao/login/reg',
    method: 'post',
    data
  })
}


export function UpdatePassword(data) {
  return request({
    url: '/api.member/resetPwd',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/api.member/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/api.member/logout',
    method: 'post'
  })
}



export function getCode(data) {
  return request({
    url: '/icao/login/getCode',
    method: 'post',
    data
  })
}

export function saveInfo(data) {
  return request({
    url: '/icao/user/saveInfo',
    method: 'post',
    data
  })
}

/**
 * 注销账号
 * @param {*} token  用户token
 * @returns  
 */
export function accountcancel() {
  return request({
    url: '/icao/user/cancel',
    method: 'get',
  })
}
