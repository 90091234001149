const state = {
    flag: null,
    one: '00', // 时
    two: '00', // 分
    three: '00', // 秒
    abc: 0, // 秒的计数
    cde: 0, // 分的计数
    efg: 0, // 时的计数
    // 计算总秒数
    totalSeconds: 0
}

const mutations = {
    START_HANDLER: (state) => {
        state.flag = setInterval(() => {
            if (state.three === 60 || state.three === '60') {
                state.three = '00';
                state.abc = 0;
                if (state.two === 60 || state.two === '60') {
                    state.two = '00';
                    state.cde = 0;
                    if (state.efg + 1 <= 9) {
                        state.efg++;
                        state.one = '0' + state.efg;
                    } else {
                        state.efg++;
                        state.one = state.efg;
                    }
                } else {
                    if (state.cde + 1 <= 9) {
                        state.cde++;
                        state.two = '0' + state.cde;
                    } else {
                        state.cde++;
                        state.two = state.cde;
                    }
                }
            } else {
                if (state.abc + 1 <= 9) {
                    state.abc++;
                    state.three = '0' + state.abc;
                } else {
                    state.abc++;
                    state.three = state.abc;
                }
            }
            state.totalSeconds ++;
        }, 1000)
    },
    // 调整计算时间
    UPDATE_HANDLER: (state, { one, two, three, abc,cde,efg }) => {
        state.one = one
        state.two = two
        state.three = three
        state.abc = abc
        state.cde = cde
        state.efg = efg
        // 计算总秒数
        state.totalSeconds = parseInt(one) * 3600 + parseInt(two) * 60 + parseInt(three)
    },
        // 调整计算时间
        UPDATE_SECONDS_HANDLER: (state, totalSeconds) => {
     
            // 计算总秒数
            state.totalSeconds = totalSeconds

            // 计算时分秒
            let hour = Math.floor(totalSeconds / 3600)
            let minute = Math.floor((totalSeconds - hour * 3600) / 60)
            let second = totalSeconds - hour * 3600 - minute * 60
            // 计算时分秒的计数
            state.abc = second
            state.cde = minute
            state.efg = hour   

            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            if (second < 10) {
                second = '0' + second
            }
            state.one = hour
            state.two = minute
            state.three = second

        },
    // 暂停计时
    END_HANDLER: (state) => {
        state.flag = clearInterval(state.flag)
        state.flag = null
        state.one= '00', // 时
        state.two= '00', // 分
        state.three= '00', // 秒
        state.abc= 0, // 秒的计数
        state.cde= 0, // 分的计数
        state.efg= 0, // 时的计数
        state.totalSeconds = 0
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
