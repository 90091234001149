<template>
 <footer>
    <!--在一行中，添加联系我们、服务协议, 文字居中，用竖线隔开-->
    <div class="row">
      <a href="/#/home/generalquestions?category=questions&id=1" target="_blank">常见问题</a>
      <span>|</span>
      <a href="/html/terms.html" target="_blank">服务协议</a>
      <span>|</span>
      <a href="/#/home/generalquestions?category=contactus&id=5" target="_blank">联系我们</a>
    </div>
    <!--编写一个白色的横线-->
    <div class="line"></div>
      {{ copyright }}<br />
      <a href="https://beian.miit.gov.cn/"  target="_blank">{{ beian }}</a>
    </footer>
</template>

<script>
import { getFooterSetting } from '@/api/home'

export default {
  name: 'homefooter',
  props: {
    
  },
  data() {
    return {
      title: '航科在线安检培训考核系统',
      beian: '',
      copyright: '',
    }
  },
  created() {
    this.getload()
  },
  methods: {
    getload(){
      getFooterSetting().then(res => {
        this.beian = res.beian
        this.copyright = res.copyright
      })
    }
  },
}
</script>
<style  lang="scss" scoped>
body a {
  text-decoration: none;
}
footer {
  margin-top: 100px;
  padding-top: 20px;
  background-color: rgba(35, 74, 102, 1);
  height: 150px;
  text-shadow: #000 0.1em 0.1em 0.1em;
  color: white;
  // line-height: 100px;
  font-size: 14px;
  text-align: center;
}
footer a {
  color: white;
}

.line {
  margin: 0 auto;
  width: 600px;
  height: 1px;
  background-color: white;
  margin-bottom: 20px;
}

footer .row {
  margin: 0 auto;
  width: 600px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
footer .row span {
  margin: 0 10px;
}

</style>