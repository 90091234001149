var zh = {
    'na_common': {
        'standard': '标准',
        'india': '印度',
        'dafault': '默认',
        'unconventional': '非常规',

        'scoringcompleted': '评分完成',
        'scoringinprogress': '评分中',
        'fileuploaded': '文件上传成功',
        'fileuploading': '文件上传中',
        'fileupload': '文件上传',
        'origin':'始发地',
        'destination': '目的地',
        'callsign': '航班号',
        // 翻译
        'translation': '翻译',
        'deleteok': '删除成功！',
        'confirm': '确定',
        'deleteconfirm': '确定删除吗？',
        'delete': '删除',
        //考试时间
        'examtime': '考试时间',
        //开始新的考试
        'startnewexam': '开始新的考试',
        //继续上次考试
        'continuelastexam': '继续上次考试',
        //系统检测您下方考试并未完成，是否继续上次考试？
        'systemdetectsunfinish': '系统检测您下方考试并未完成，是否继续上次考试？',
        'accountcancel': '账号注销',
        'instructions':'操作说明',
        'emailaddress':'邮箱地址',
        'pleaseinputyouremailaddress':'请输入邮箱',
        'pleaseinputyourfeedbackinformation':'请输入反馈内容',
        'pleaseinputthebrowseranditsversionnumber':'请输入所使浏览器及其版本',
        'occurrencetime':'涉及时间',
        'selectdate':'选择日期',
        'selecttime':'选择时间',
        'attachmentupload':'附件',
        'audioisbeinguploaded':'文件正在上传中....',
        'relearn':'重新学习',
        'clickandupload':'点击上传',
        'feedbackinformation':'反馈内容',
        'feedbacksuccess':'反馈成功！',
        'contactus': '联系我们',
        'ok':'确定',
        'pleaseselectthecompletedateandtime':'涉及时间填写不完整！',

        'brower': '浏览器',
        'continue': '继续学习',
        'course': '课程',
        'allcourses': '所有课程',
        'alltrain': '所有训练',
        'self-testing': '自我测试',
        'allsimulation': '所有模拟考试',
        'icaoenglish': 'ICAO 英语',
        'examstart': '开始考试',
        'trainstart': '开始训练',
        'testingstart': '开始测试',
        'language': '选择语言',
        'profilepages': '个人资料',
        'changepassword': '修改密码',
        'newpassword': '重置密码',
        'confirmpassword': '确认密码',
        'tryout': '试用',
        'signup': '注册',
        'signin': '登录',
        'email': '邮箱',
        'mobilePhone': '手机号码',
        'password': '密码',
        'tips': '提示',
        'error': '错误',
        'success': '正确',
        'partcorrect': '部分正确',
        'allquestnum': '总题数',
        'submit': '提交',
        'nextquestion': '下一题',
        'upquestion': '上一题',
        'question': '问题',
        'answerendtipsright': '回答正确！',
        'answerendtipsrequired': '必须全部填写完整！',
        'answerendtipswrong': '有错误项！',
        'history': '历史记录',
        'historyresult': '结果',
        'historytime': '日期',
        'loginusernamerequired': '输入邮箱/电话号码不能为空！',
        'loginusernamegreaterthan3': '输入长度必须大于3位！',
        'loginphoneerrortips': '输入手机号码错误！',
        'loginmailerrortips': '输入邮箱格式错误！',
        'loginpasswordnotless6': '输入密码不能少于6位！',
        'phonecodelenght6': '输入长度必须为6位！',
        'messagecode': '短信验证码',
        'getmessagecode': '获取验证码',
        'registrationtips': '欢迎你来到本系统，请在下方完成注册吧！',
        'registrationagreement': "已阅读并同意此系统用户服务协议",
        'registrationagreementtip': "必须接受此协议中的条款，才能使用本程序",

        'recording': '开始录制',
        'finishrecording': '完成录制',
        'upload': '上传',
        'uploadcancel': '取消重来',

        'searchkeyword': '搜索关键字',
        'category': '分类',
        'search': '查询',
        'number': '序号',
        'original': '原文',
        'operation': '操作',
        'download': '下载',
        'all': '全部',
        'level': '级别',
        'controller': '管制员',
        'pliot': '飞行员',
        'text': '文本',

        'pleaselistencarefully': '请仔细听',
        'nowplay': '正在播放中...',
        'recordingremain': '还可录制',
        'secends': '秒',
        'finished': '已完成',

        'score': '成绩',
        'scores': '成绩',
        'getscore': '得分',
        'status': '状态',

        'devicetest': '设备测试',
        'permissionsanddevices': '权限和设备',
        'testingrecord': '测试录音',
        'Listenrecordandenter': '听取录音并进入',
        'clickbuttontestingpermissionsanddevices': '点击此按钮检测录音权限和设备',
        'clickbuttontostarttestingrecord': '点击此按钮开始录音',
        'stoprecording': '结束测试录音',
        'playrecord': '播放测试录音',
        'testenter': '进入',
        'textagain': '重新检测',

        'examok': '开始考试',
        'cancel': '取消',
        'show': '查看',
        'exercises': '课后练习',
        'fullscreen': '全屏',
        'list': '列表',
        'card': '卡片',
        'phrasebook': '生词本',
        'save': '保 存',
        'ascending': '升序',
        'descending': '降序',
        'SimulationHistory': '模拟考试 历史记录',
        'waitforgrading': '等待判卷...',
        'incomplete': '未完成作答',
        'unfinished': '未完成作答',
        'unqualified': '不合格',
        'papername': '试卷名称',
        'time': '时间',
        'latestnotice': '最新通知',
        'describe': '描述',
        'sendtime': '发送时间',
        'playback': '录音回放',
        'equipmenttesting': '设备测试',
        'feedback' : '问题反馈',
        'menuaddquestion':'提交真题',

        'easy': '简单',
        'difficulty': '困难',

        'modifysuccess': '修改成功！',
        'subscription' : '会员续费', 
        'vip' : 'VIP 会员',
        'expirydate' : '有效期至',
        'YouhavenotyetbecomeaVIPmemberAfterbecomingaVIPmemberyoucanenjoymoreservices':'您还未成为VIP会员，成为VIP会员后，可以享受更多的服务',
        'vipendtime':'您的VIP会员已到期',
        'membershiprecord' : '开通记录',
        'openingtime':'开通时间',
        'expirationtime':'到期时间',
        'openingtype':'开通类型',
        'additionaldays':'增加天数',
        'enterprisecustomerservice':'企业客服微信',
        'close': '关闭',
        'welcomesystem' : '欢迎来到航科在线',
        'welcomesystemtrialdesstart' : '我们为您提供',
        'welcomesystemtrialdesmiddle' : '天的免费课程体验。以便您可以深入了解我们的系统。点击下方按钮，开通',
        'welcomesystemtrialdesend' : '天免费体验。',
        'freeopening' : '免费开通',
        'welcomesystemexpirationdesstart' : '您的',
        'welcomesystemexpirationdesend' : '天免费体验已到期！暂时无法学习相关课程。如果您喜欢我们的课程，并想继续学习。您点击下方按钮，购买我们的课程。',
        'purchasecourse' : '购买课程',
        'successfullyopened' : '开通成功',
        'welcomesystemvipenddes' : '您的VIP会员已到期！暂时无法学习相关课程。如果您还喜欢我们的课程，并想继续学习。您点击下方按钮，继续购买我们的课程。',
        'scheduleqa' : '预约答疑',
        'appointmentform' : '预约表单',
        'appointmentlist' : '预约列表',
        'appointmentinstructions' : '预约说明',
        'appointmentinstructionsdes' : '答疑会以腾讯会议的形式进行，预约成功后，会以短信形式发送腾讯会议号和密码，如有疑问请联系客服。',    
        'appointmenttime' : '答疑时间',
        'remainingcount' : '剩余次数',
        'notremaingcountdes' : '暂无可用次数，请续费后使用',
        'available': '可用',
        'times': '次',
        'booknow' : '立即预约',
        'payforbooking': '支付预约',
        'termsofservice': '服务条款',
        'termsofservicehaveread': '我已阅读，理解并接受',
        'broadcasttime' : '开播时间',
        'notstarted' : '未开播',
        'liveed' : '已开播',
        'ended' : '已结束',
        'reserved' : '已预约',
        'cancelreservation' : '取消预约',
        'reservation' : '预约',
        'unpaid' : '未支付',
        'cancelled' : '已取消',
        'more' : '更多',
        'title' : '标题',
        'appointmentstatus' : '预约状态',
        'coursestatus' : '课程状态',
        'viewplayback' : '查看回放',
        'selectpaymentmethod' : '选择支付方式',
        'confirmpayment' :  '确认支付',
        'onlyreadpaytipsdes' : '只有阅读并接受协议，才能支付！',
        'nickname' : '昵称',
        'orderlist' : '订单记录',
        'ordernumber' : '订单号',
        'paymentmethod' : '支付方式',
        'paymenttime' : '支付时间',
        'paymentstatus' : '支付状态',
        'weChatcustomerservice':'微信客服',
        'quxiaotips': '若您需要取消预约，请在开课前至少提前1小时进行操作。取消后，预约机会将保留，您可以在下次继续使用。',
        'selecticaocourses': '请选择其中一个课程：',
        'hisselecticaocourses': '根据您的历史选择，系统为你默认选中如下课程：',
    },
    'icao_common': {
        'yingshikecheng' : '应试课程',
        'icaoencoursespepec':'ICAO英语系列课程 - PEPEC',
        'referenceanswer': '参考答案',
        'interviewwarmuptitle': '准备阶段',
        'interviewwarmupcontent': "该部分是面试的第一阶段，主要使考生熟悉面试员的语音语调。",
        'interviewwinddowntitle': '结束阶段',
        'interviewwinddowncontent': '该部分是面试的第四阶段，提问逐步转入普遍性的话题，使考生情绪放松，结束考试。',
        'answer_finished_info_ai_score': 'AI正在评卷中，请在"历史记录"或"最新通知"中查看',
        'learningcenter':'学习中心',
        'viewtheresults':'查看成绩',
        'onlyjpgpngmp4file':'只能上传jpg/png/mp4文件，且不超过10M。',
        'onlyjpgpngmp4uploaded':'上传文件只能是 JPG、PNG、MP4 格式! ',
        'onlyjpgpngmp4tenm':'上传文件大小不能超过 10MB! ',
        'feedbackxianzhi1':'当前限制选择',
        'feedbackxianzhi2':'个文件，本次选择了',
        'feedbackxianzhi3':'个文件，共选择了',
        'feedbackxianzhi4':'个文件。',

        'story':'故事',
        'selftaught': '自主学习',
        'specifictraining': '专项训练',
        'simulation': '模拟考试',

        'usercenter': '用户中心',
        'exit': '退出',

        'knowledgeprinciple': '知识与原理',
        'phraseologyterminology': '术语与词汇',
        'recentcourses': '最近学习课程',
        'pepec': '飞行英语',
        'maintenance': '机务英语',
        'pilot': '飞行员课程',
        'flightprinciple': '飞行原理',
        'flightprinciplecontent': '飞行原理 ， 空气动力学 ， 飞行控制',
        'jeppesenchart': '飞行运行及航行资料',
        'jeppesenchartcontent': '飞行手册与文件  ， 飞机操作  ， 空域',
        'aircraftstructure': '飞机结构',
        'aircraftstructurecontent': '飞机结构 ，飞机系统 ，飞行仪表',
        'meteorology': '航空气象及服务',
        'meteorologycontent': '天气原理 ，航空气象服务',
        'airnavigationinstruments': '领航与仪表',
        'airnavigationinstrumentscontent': '飞行仪表，领航',
        'flightperformanceBalance': '飞行性能与配载',
        'flightperformanceBalancecontent': '配载与平衡 ，飞机性能',
        'CRMhumanfactors': '机组资源管理与人为因素',
        'CRMhumanfactorscontent': '航空医学，航空决策',

        'listening': '听力',
        'pepec900': '900句',
        'RepeatingRadiotelephonySentences':'语句复诵',
        'pepec900selftesting': '900句自测',
        'pepec900selftestingstart': '900句自测介绍页',
        'simulatingradiotelephonycommunication': '情景对话训练',
        'simulatingradiotelephonycommunication-selftesting': '情景对话训练自测',
        'simulatingradiotelephonycommunication-selftestingscore': '情景对话训练自测成绩',
        'simulatingradiotelephonycommunication-selftesting-start': '情景对话训练自测介绍页',
        'oralproficiencyinterview': '面试',
        'oralproficiencyinterviewselftesting': '面试自测',
        'oralproficiencyinterviewselftestingstart': '面试自测介绍页',
        'oralproficiencyinterviewselftestingscore': '面试自测成绩',
        'retellingstory': '故事复述',
        'retellingstoryselfstesting': '故事复述自测',
        'retellingstoryselfstestingscore': '故事复述自测成绩',
        'retellingstoryselfstestingstart': '故事复述自测介绍页',

        'listenphase': '阶段',
        'listentype': '类型',
        'listenerror': '错题',

        'fillintheblanks': '填空',
        'fillintheblanksdescribe': '在这一部分，你将听到陈述或对话。用你在录音中听到的信息填空。',
        'listeningcomprehension': '听力理解',
        'listeningcomprehensiondescribe': '在这一部分，你将听到关于航空英语的陈述或飞行员和管制员之间的交流。听完陈述或对话后，你应该从四个选项中选择一个最佳答案。',
        'listeningandspeaking': '听说',
        'listeningandspeakingdescribe': '在这一部分，你将听到一些对话或短文。每段对话或短文后有三个问题，仔细听，然后回答问题。',

        'part1': '第一部分',
        'part2': '第二部分',
        'part3': '第三部分',
        'part4': '第四部分',
        'part5': '第五部分',
        'part6': '第六部分',

        'trainendandretrain': '本训练您已完成，是否重新训练？',
        'continuetrain': '继续训练',
        'retrain': '重新训练',
        'errorqustionnum': '错题数',
        'nowrongquestion': '暂无错题',
        'wrongtopic': '错题',
        'statementsorconversations': '陈述或对话',
        'pleasefillintheblanks': '请填空',
        'answeranalysis': '     答案解析',

        'correctanswer': '正确答案',
        'myanswer': '我的回答',
        'pleasechoosethebestanswer': '请选择最佳答案',

        'exchangesorpassages': '对话或短文',
        'pleaseanswerthequestions': '请回答以下问题',

        'searchconditions': '搜索',
        'searchoriginal': '搜索',
        'searchlevel': '等级选择',
        'searchscene': '情景选择',
        'levelsort': '等级排序',
        'learnprogress': '学习进度',
        'answersituation': '作答情况',
        'trainevaluation': '训练评估',
        'playcontrol': '播放控制',
        'singleplay': '单个播放',
        'orderplay': '顺序播放',
        'cycleplay': '随机播放',
        'isrecord': '录音',
        'pleaseselectleft': '请选择左侧语句！',
        'pleasechecksonicgraph': '请检测音波图是否正常！正常请确认正常。',
        'confirmnormal': '确认正常',
        'replay': '重放',
        'ready': '准备',
        'originalhide': '原文已隐藏！',
        'evaluate': '评价',

        'hundrednextpagetips1': '本页音频已播放完毕！',
        'hundrednextpagetips2': '是否进入下一页？',
        'stoppage': '停留本页',
        'innextpage': '进入下一页',
        'hundredcorrectanswer': '回答正确',
        'hundredwronganswer': '回答错误',
        'hundredlearned': '已学',
        'hundrednotlearned': '未学',
        'levelascending': '级别升序',
        'leveldescending': '级别降序',

        'repeatpractice': '复述练习',
        'repeatpracticetips': '你将听到离场和进场的ATIS，复述你在ATIS中听到的消息。',
        'roleplayexcercises': '模拟陆空通话练习',
        'roleplayexcercisestips': '在这一部分中，您将扮演飞行员的角色，并在模拟飞行中与空中交通管制员进行对话。',
        'roleplayexcercisesselftesting': '模拟陆空通话自我测试',

        'retelling': '请复述',
        'interviewready': '面试准备中...',
        'interviewer': '面试官',
        'interviewee': '被面试者',
        'startinterview': '开始面试',
        'closeinterview': '结束面试',

        'exchange': '对话',

        'pronunciation': '发音',
        'structure': '结构',
        'vocabulary': '词汇使用',
        'fluency': '流畅程度',
        'comprehension': '理解',
        'interaction': '应对能力',

        'icao3tips': '次工作级',
        'icao4tips': '工作级',
        'icao5tips': '专家级',
        'pleaseselectpaper': '请选择模拟试卷',

        //术语与词汇设置
        'sychlistshowinterpretation': '列表中显示释义',
        'sychautoaudioplay': '单词浏览时自动发音',
        'sychplayshowinterpretation': '播放时直接显示释义',
        'sychstudyhabitssetting': '学习习惯设置',
        'seethedefinition': '查看释义',
        'moredefinition': '更多释义',
        'detaileddefinition': '详细释义',
        'meaning': '含义',
        'coursesentence': '课程原句',
        'examplesentences': '例句',

        'word': '单词',
        'phonetic': '音标',
        //规章文献
        'literature': '规章文献',
    },
    'aets_common': { //空管英语
        'aststitle': '空管英语',
        'airtrafficcontrollers': '空管课程',
        'listentothenmainidea':'听力理解',
        'storyretelling':'故事复述',
        'listenandanswerthequestions':'听力简答',
        'interactivesimulationtest':'模拟通话',
        'oralproficiencyinterview':'口语能力面试',
        'listeningeasycontent': '在这一部分，你将听到正常或非正常陆空通话，每段对话1个来回，听完对话后，需要完成1道单选题。',
        'listeningdifficultycontent': '这一部分，你将听到正常或非正常陆空通话，每段对话4-6个来回，听完对话后，需要完成3-4道单选题。',
        'listeningshorteasycontent': '在这一部分，你将听到一段短对话，听完对话后，需要口头回答1道问题。',
        'listeningshortdifficultycontent': '在这一部分，你将听到一段长对话，听完对话后，需要口头回答3道问题。',
        'roleplayexcercisestips': '在这一部分，你将扮演管制员的角色，以武汉天河机场为原型，在模拟的国际航空运行情景下完成管制英语指挥。',
        'intervieweasytitle': '初步定级',
        'intervieweasycontent': '该部分是面试的第二阶段，提问逐步引入与工作相关话题，初步判断语言水平。',
        'interviewdifficultytitle': '深度交谈',
        'interviewdifficultycontent': '该部分是面试的第三阶段，包含看图说话，深入地提问以检测语言能力。',
    },
    'maintenance_common': { //机务英语
        'maintenance': '机务课程',
        'aviationenglish': '航空英语',
        'aviationenglishforaircraftmaintenance': '机务英语',
        'CCAR66R3': 'CCAR-66 R3',
        'CCAR66R3formaintenancelicense': 'CCAR-66 R3 机务执照英语',
        'aircraftstructurecontent': '飞机结构',
        'aircraftsystems': '飞机系统',
        'aircraftsystemscontent': '飞机系统 ，飞行仪表',
        'listeningpractice': '听力测试',
        'readingcomprehension': '综合阅读',
        'sentences': '句子理解',
        'exchanges': '情景对话',
        'passages': '短文',
        'englishtochinese': '英译汉',
        'chinesetoenglish': '汉译英',
        'englishtoenglish': '英译英',
        'singlechoice': '单选题',
        'navmain': '主页',
    },
    'cc_common': { //英语Cabin Crew
        'cctitle': '空乘英语',
        'ccflightattendants':'乘务课程',
    }
}

export default zh