import router from './router'
import store from './store'
import { getToken } from '@/utils/auth' // get token from cookie

const whiteList = ['/', '/home/course', '/home/about','/home/vip','/home/generalquestions', '/login', '/auth-redirect', '/literature','/loginhk'] // no redirect whitelist

const examrouterule = {"ExamListInstructions": "ExamListenTest",  "ExamListenTest": "ExamListenTestScore",  "ExamListenTestScore": "ExamRepeatingHot",  "ExamRepeatingHot": "ExamRepeatingTest",  "ExamRepeatingTest": "ExamRepeatingTestScore",  "ExamRepeatingTestScore": "ExamRolePlayTest",  "ExamRolePlayTest": "ExamRolePlayTestScore",  "ExamStoryTest": "ExamStoryTestScore","ExamStoryTestScore": "ExamInterview",  "ExamInterview": "ExamInterviewScore","ExamInterviewScore": "ExamHistoryScore"}

const icaorouters = ["coursemain","course","courseparts","coursepartssingle","coursetrain","phraseortermin","phraseorterminbooks","phraseortermincard","trainmain","TrainListen","TrainListenb","TrainListenBlank","TrainListenSelect","TrainListenQa","trainninehundred","TrainListenNinehundredTest","TrainListenNinehundredTestStart","TrainListenTest","TrainListenTestStart","TrainSRC","TrainSRCATIS","TrainSRCRolePlay","TrainSRCRolePlayTest","TrainSRCRolePlayTestStart","TrainInterviewList","TrainInterview","TrainInterviewTest","TrainInterviewTestStart","TrainStory","TrainStoryTest","TrainStoryTestStart","ExamList","ExamListInstructions","ExamListenTest","ExamRepeatingTest","ExamRepeatingHot","ExamRolePlayTest","ExamInterview","ExamStoryTest"]


router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in
  const hasToken = getToken()

  // tmp 航科 只显示hkchat页面------------------------------------
  if(to.path == '/hkopi' || to.path == '/loginhk'){
    document.title = "航科在线"
  }
  // end tmp 航科 只显示hkchat页面------------------------------------

  if (hasToken) {
    // get username
    await store.dispatch('user/getInfo')

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/coursemain' })
    } else {
      //模拟考试组织，防止用户刷新页面
      if (examrouterule[from.name]!=null && to.name != examrouterule[from.name]) {
        const answer = window.confirm(
          '你确定要离开当前页面吗? 您的练习并未提交!'
        )
        //console.log(answer)
        if (answer) {
          next()
        } else {
          //console.log('阻止')
          next(false)
          return false
        }
      }
      //console.log('这里还来吗')
      
      //进入飞行英语页面，判断是否有权限
      if (icaorouters.indexOf(to.name) != -1 ) {
        if (store.getters.fxVip == 0) {
          next({ path: '/dashboard' })
        }
      }

      next()
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
})
