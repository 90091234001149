import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.get(TokenKey, { domain: domain })
}

export function setToken(token) {
  // 获取 .env 文件中的 domain 配置
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.set(TokenKey, token, { domain: domain })
}

export function removeToken() {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.remove(TokenKey,{ domain: domain })
}
